import c3api from "@/c3api";
import { ref } from "vue";
import { useToast } from "vue-toastification";

export default function useLps() {
  const lpLoading = ref(false);

  const lps = ref([]);
  const lp = ref([]);
  const toast = useToast();

  const fetchLps = async ( params = null ) => {
    try {
      lpLoading.value = true;
      const response = await c3api.get(`/lps`, {params: params});
      return (lps.value = response.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      lpLoading.value = false;
    }
}

  const fetchLp = async (id ,params = {}) => {
    try {
      lpLoading.value = true;
      const response = await c3api.get(`/lps/${id}`, {params: params});
      console.log(response.data.data);
      return (lp.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      lpLoading.value = false;
    }
  };

  const fetchLpItemTransactions = async (id, params = {}) => {
    try {
      lpLoading.value = true;
      const response = await c3api.get(`/lps/${id}/lp_item_transactions`, {params: params});
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      lpLoading.value = false;
    }
  };

  const fetchLpInventoryTransactions = async (id) => {
    try {
      lpLoading.value = true;
      const response = await c3api.get(`/lps/${id}/inventory_transactions`);
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      lpLoading.value = false;
    }
  };

  const voidLpTransaction = async (id, txn_id, params) => {
    try {
      lpLoading.value = true;
      const response = await c3api.patch(`/lps/:${id}/transactions/${txn_id}/void`, params);
      toast.success("Transaction voided successfully");
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      lpLoading.value = false;
    }
  };

  return {
    lps,
    fetchLps,
    fetchLp,
    fetchLpItemTransactions,
    fetchLpInventoryTransactions,
    lp,
    voidLpTransaction,
  };
}
